import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import BannerRender from "@components/modules/Banner/BannerRender";
import { cleanP } from "@components/utils/CleanedHtml";
import { Routes } from "@components/routes";
//import GoogleMultipleMap from "@components/maps/google/multiple";
import ModuleRender from "@components/modules/moduleRender";

import AreaDetails from "../../../templates/area-guide-details";
import ProjectDetails from "../../../templates/project-details";

const GoogleMultipleMap = loadable(() => import("@components/maps/google/multiple"));

const AreaLandingPages = ({ pageContext, data, location }) => {
  let myareaguideslug = ''
  let myprojectslug = ''
  let useAreaGuide = false;
  let pathUri_main = location.pathname.split("/buy-property-in-uae/uae-area-guides/");
  if(location.pathname == "/buy-property-in-uae/uae-area-guides/" || location.pathname =="/buy-property-in-uae/uae-area-guides"){
    useAreaGuide = true;
  }
  if ( pathUri_main[1] ) {
 
    let pathUri_arr = pathUri_main[1].split("?")[0].split("/")
    if(pathUri_arr[1]){
        myprojectslug = pathUri_arr[1];
    } else {
        myareaguideslug = pathUri_arr[0];
    }
  }

  if (myprojectslug) {
    return (
      <ProjectDetails slug={myprojectslug} />
    )
  } else if (myareaguideslug) {
    return (
      <AreaDetails slug={myareaguideslug} />
    )
  } else {

    const { glstrapi: { pages, areaGuides } } = data;
    var page = pages[0]
    const breadcrumbs = [
        { label: "Buy", url: useAreaGuide ? `/buy-property-in-uae/` : `${Routes.Buy}` },
        { label: page?.Pagename, url: `${Routes.Buy}/${page?.Slug}` },
    ];

    const defaultCenter = {
        lat: 25.054387006289776,
        lng: 55.23080857595263,
    };

    return (
    <Layout popularSearch={page?.Popular_Search}>
      <Seo
        title={page?.Metadata?.title ? page.Metadata.title : cleanP(page.Title)}
        description={
          page?.Metadata?.description ? page.Metadata.description : null
        }
        image={
          page?.Metadata?.image?.url_sharp?.childImageSharp
            ? page.Metadata.image.url_sharp.childImageSharp.resize
            : null
        }
      />
      <BannerRender page={page} breadcrumbs={breadcrumbs} />
      {<GoogleMultipleMap
                markers={areaGuides}
                {...defaultCenter}
                zoom={10}
                className="area-guides-landing-map"
            />}
      {page?.BannerImage && <ModuleRender moduleData={page?.BannerImage} />}
    </Layout>
    )
  }
};

export default AreaLandingPages;

export const query = graphql`
  query {
    glstrapi {
      pages(publicationState: LIVE, where: {PageTemplate: "AreaLanding"}, limit: 1) {
        Pagename
        Title
        Slug
        Popular_Search
        BannerImage {
          ... on GLSTRAPI_ComponentModulesBannerImage {
            ...ImageBannerFragment
          }
          ... on GLSTRAPI_ComponentModulesAllAreas {
            ...AllAreasFragment
          }
        }
        Metadata {
          title
          description
          image {
            url
            url_sharp {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
      areaGuides(publicationState: LIVE) {
        id
        Title
        Slug
        MapLong
        MapLat
        BannerImage {
          ... on GLSTRAPI_ComponentModulesBannerImage {
            id
            ItemText
            ItemTitle
            Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(width: 40, height: 40, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  }
`;
